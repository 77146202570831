.swiper-button-prev,
.swiper-button-next {
  color: green;
  font-size: 10px !important; /* Adjust the font size to your desired value */
}

.swiper-pagination-bullet {
    width: 10px; /* Adjust the width to your desired size */
    height: 10px; /* Adjust the height to your desired size */
  }

@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 10px !important; /* Adjust the font size for small screens */
  }
}
