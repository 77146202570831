.card :hover {
  color: #000;
  background-color: #fff;
}
/* .lbtn :hover {
  color: #000;
  background-color: #fff;
} */
.bottom {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../Assets/bottom.png) no-repeat center bottom;
}
.title-back{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url(../../Assets/bottom.png) no-repeat center bottom;
}

.image-container {
  position: relative;
  width: 200px; /* Set the initial width */
  overflow: hidden;
}

.image-container img {
  width: 100%; /* Make the image responsive */
  height: auto;
  transition: transform 0.5s ease-in-out;
}

.image-container:hover img {
  transform: scale(1.2); /* Apply zoom effect on hover */
}


/* =--------------MODAL----------------- */
/* .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #013220;
  color: #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
  overflow: hidden;
} */

/* .modal-scroll-content {
    max-height: 300px; /* Adjust the max-height as needed */
    /* overflow-y: auto;
  } */ 

/* .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
} */
